import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import Breadcrumbs from '../../Components/Breadcrumb';
import SEO from '../../Components/SEO';
import VocabularyMenu from '../../Components/VocabularyMenu';
import phrasalverbsData from './Data/phrasalverbs.json';
import Sidebar from '../../Components/Sidebar';

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const PhrasalVerbs = () => {
    const [answers, setAnswers] = useState(Array(10).fill(null));
    const [evaluation, setEvaluation] = useState(Array(10).fill(''));
    const [displayedSentences, setDisplayedSentences] = useState([]);
    const [options, setOptions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const initialPhrasalVerbsWithOptions = useMemo(() => phrasalverbsData, []);

    const generateNewPhrasalVerbs = useCallback(() => {
        const newPhrasalVerbsWithOptions = initialPhrasalVerbsWithOptions.slice(currentIndex, currentIndex + 10);
        const displayedSentences = newPhrasalVerbsWithOptions.map(idiom => idiom.sentence);

        const updatedOptions = newPhrasalVerbsWithOptions.map(idiom => {
            const correctAnswer = idiom.options[idiom.correctIndex]; // Get the correct answer
            const shuffledOptions = shuffleArray([...idiom.options]); // Shuffle options
            const newCorrectIndex = shuffledOptions.findIndex(option => option === correctAnswer); // Get new index of correct answer

            return {
                options: shuffledOptions.map(option => ({ label: option, value: option })), // Set shuffled options
                correctIndex: newCorrectIndex // Store new index
            };
        });

        setDisplayedSentences(displayedSentences);
        setOptions(updatedOptions);
        setAnswers(Array(10).fill(null));
        setEvaluation(Array(10).fill(''));
    }, [currentIndex, initialPhrasalVerbsWithOptions]);

    useEffect(() => {
        generateNewPhrasalVerbs();
    }, [currentIndex, generateNewPhrasalVerbs]);

    const handleChoice = (index, selectedOption) => {
        if (selectedOption !== null) {
            const updatedAnswers = [...answers];
            updatedAnswers[index] = selectedOption;
            setAnswers(updatedAnswers);

            // Update options to remove selected option for that index
            const updatedOptions = options.map((opt, i) =>
                i === index
                    ? {
                        ...opt,
                        options: opt.options.filter((option) => option.value !== selectedOption.value),
                    }
                    : opt
            );
            setOptions(updatedOptions);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log("Selected Answers:", answers);
        console.log("Options Structure:", options);
        
        const updatedEvaluation = answers.map((selectedOption, index) => {
            if (!selectedOption) {
                console.log("No option selected for index:", index);
                return '';
            }
    
            // Get the original idiom for correct answer
            const originalIdiom = initialPhrasalVerbsWithOptions[currentIndex + index]; // Use the original data to get the correct answer
            const correctAnswer = originalIdiom.options[originalIdiom.correctIndex];
    
            console.log(`Selected Option for index ${index}:`, selectedOption.value);
            console.log(`Correct Answer for index ${index}:`, correctAnswer);
            
            const isCorrect = selectedOption.value === correctAnswer;
            console.log(`Is Selected Correct? ${isCorrect}`);
            
            return isCorrect ? 'correct' : 'incorrect';
        });
    
        setEvaluation(updatedEvaluation);
    };
    
    const handleNextExercise = () => {
        const nextIndex = (currentIndex + 10) % initialPhrasalVerbsWithOptions.length;
        setCurrentIndex(nextIndex);
    };

    const handlePreviousExercise = () => {
        const previousIndex = (currentIndex - 10 + initialPhrasalVerbsWithOptions.length) % initialPhrasalVerbsWithOptions.length;
        setCurrentIndex(previousIndex);
    };

    return (
        <div>
            <SEO
                title='Phrasal Verbs Exercise - Vocabulary Exercises'
                description='A collection of idiom exercises to practice understanding and usage in business contexts'
                name='English Exam Exercises'
                type='article' />
            <div className="introtext">
                <Breadcrumbs />
                <h3 className="mt-2">Business Phrasal Verbs</h3>
                <p>Choose the correct idiom from the dropdown menu.</p>
            </div>

            <div className="grammar-container">
                <div className="exercises-container">
                    <div className="buttonctrl">
                        <button type="button" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i></button>
                        <button type="button" onClick={handleNextExercise}><i className="fas fa-arrow-right"></i></button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {displayedSentences.map((sentence, index) => {
                            const parts = sentence.split("___");
                            return (
                                <div key={index}>
                                    <div className="sentence-container">
                                        <span className="circle">{index + 1}</span>
                                        {parts[0]}
                                        <Select
                                            value={answers[index]}
                                            onChange={(selectedOption) => handleChoice(index, selectedOption)}
                                            options={options[index].options}
                                            isSearchable={false}
                                        />
                                        {parts[1]}
                                        <button type="submit" className='check-button'>Check</button>
                                        {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                                    </div>
                                </div>
                            );
                        })}
                        <button type="button" className='check-button' onClick={handlePreviousExercise}>Previous</button>
                        <button type="button" className='check-button' onClick={handleNextExercise}>New Exercise</button>
                    </form>
                </div>
                <div>
                    <VocabularyMenu />
                    <Sidebar />
                </div>
            </div>
        </div>
    );
};

export default PhrasalVerbs;
