import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import Breadcrumbs from '../../Components/Breadcrumb';
import SEO from '../../Components/SEO';
import VocabularyMenu from '../../Components/VocabularyMenu';
import sentencesData from './Data/linkingwords.json';
import Sidebar from '../../Components/Sidebar';

const LinkingWords = () => {
  const [answers, setAnswers] = useState(Array(10).fill(null));
  const [evaluation, setEvaluation] = useState(Array(10).fill(''));
  const [displayedSentences, setDisplayedSentences] = useState([]);
  const [options, setOptions] = useState([]);
  const [sentencesWithOptions, setSentencesWithOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const initialSentencesWithOptions = useMemo(() => sentencesData, []);

  const generateNewSentences = useCallback(() => {
    const newSentencesWithOptions = initialSentencesWithOptions.slice(currentIndex, currentIndex + 10);
    const displayedSentences = newSentencesWithOptions.map(sentence => sentence.sentence);
    const options = newSentencesWithOptions.map(sentence => ({
      options: sentence.options.map(option => ({ label: option, value: option })),
      correctIndex: sentence.correctIndex
    }));

    setSentencesWithOptions(newSentencesWithOptions);
    setDisplayedSentences(displayedSentences);
    setOptions(options);
    setAnswers(Array(10).fill(null));
    setEvaluation(Array(10).fill(''));
  }, [currentIndex, initialSentencesWithOptions]);

  useEffect(() => {
    generateNewSentences();
  }, [currentIndex, generateNewSentences]);

  const handleChange = (index, selectedOption) => {
    if (selectedOption !== null) {
      const updatedAnswers = [...answers];
      updatedAnswers[index] = selectedOption;
      setAnswers(updatedAnswers);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedEvaluation = answers.map((selectedOption, index) => {
      if (selectedOption === null || selectedOption.value === null) {
        console.log("No option selected for index:", index);
        return '';
      }
      const { options: sentenceOptions, correctIndex } = sentencesWithOptions[index];
      const correctAnswer = sentenceOptions[correctIndex];
      if (selectedOption.value === correctAnswer) {
        console.log("Answer for index", index, "is correct");
        return 'correct';
      } else {
        console.log("Answer for index", index, "is incorrect");
        return 'incorrect';
      }
    });
    console.log("Updated evaluation:", updatedEvaluation);
    setEvaluation(updatedEvaluation);
  };

  const handleNextExercise = () => {
    const nextIndex = (currentIndex + 10) % initialSentencesWithOptions.length;
    setCurrentIndex(nextIndex);
  };
  const handlePreviousExercise = () => {
    const previousIndex = (currentIndex - 10 + initialSentencesWithOptions.length) % initialSentencesWithOptions.length;
    setCurrentIndex(previousIndex);
  };
  return (
    <div>
      <SEO
        title='Linking Words - Vocabulary Exercises - English Exam Exercises'
        description='A collection of texts to practice reading comprehension'
        name='English Exam Exercises'
        type='article'
      />
        <Breadcrumbs />
        <div className="buttonctrl">
                                        <button type="button" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i></button>
                                        <button type="button" onClick={handleNextExercise}><i className="fas fa-arrow-right"></i></button>
                                        </div>
        <div className="home-container">
      <div className="introtext">
        <h3 className="mt-2">Linking Words</h3>
        <p>Choose the correct linking word from among these in the box.</p>
        <p className="wordbox">so, which, until, why, while, when, as, before, after, until, as long as, whenever, because, as soon as, since, once, though, if, in case, even though, as if</p>
      </div></div>
      <div className="grammar-container">
        <div className="exercises-container">
        

          <form onSubmit={handleSubmit}>
            {displayedSentences.map((sentence, index) => {
              const parts = sentence.split("___");
              return (
                <div key={index}>
                  <div className="sentence-container">
                    <span className="circle">{index + 1}</span>
                    {parts[0]}
                    <Select
                      value={answers[index]}
                      onChange={(selectedOption) => handleChange(index, selectedOption)}
                      options={options[index].options}
                      isSearchable={false}
                      // No styles prop here; using default styles
                    />
                    {parts[1]}
                    <button type="submit" className='check-button'>Check</button>
                    {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                  </div>
                </div>
              );
            })}
            
            <button type="button" className='check-button' onClick={handlePreviousExercise}>Previous</button>            <button type="button" className='check-button' onClick={handleNextExercise}>New Exercise</button>
          </form>
        </div>
        <div><VocabularyMenu /><Sidebar /></div>
       
      </div>
    </div>
  );
};

export default LinkingWords;
